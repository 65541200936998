<template>
     <a
          @click="openDailyBonus()"
          class="flex items-center py-2 rounded-lg group cursor-pointer get-coins"
     >
          <div
               class="w-full h-10 sm:h-11 flex flex-col items-center justify-center gap-0 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 daily-bonus-container text-secondary-3 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
          >
               <span
                    class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold"
                    >DAILY BONUS</span
               >
               <span
                    class="w-full text-changing-color-animation tracking-wider text-center font-roboto text-xs sm:text-sm font-extrabold -mt-1"
               >
                    {{
                         difference > 0
                              ? hours + ":" + minutes + ":" + seconds
                              : "Ready"
                    }}
               </span>
          </div>
     </a>
</template>
<script>
import { watch, ref, computed } from "vue";
import { bonusStore } from "store/bonus.js";
import eventBus from "plugins/event.js";
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";

export default {
     props: ["onClaimStatus"],
     setup(props) {
          const useBonusStore = bonusStore();
          const { money } = useFormatter();

          const dailyBonus = ref(null);

          const difference = ref(null);
          const days = ref(0);
          const hours = ref("00");
          const minutes = ref("00");
          const seconds = ref("00");

          const details = computed(() => {
               return useBonusStore.details;
          });

          const data = computed(() => {
               difference.value = null;
               if (useBonusStore.dailyBonus) {
                    dailyBonus.value = useBonusStore.dailyBonus;
                    difference.value =
                         +new Date(
                              useBonusStore.dailyBonus["next_claim_start"]
                         ) - +new Date(useBonusStore.dailyBonus["now"]);
               }
               return useBonusStore.dailyBonus;
          });

          function countdownTimer() {
               if (difference.value > 0) {
                    useBonusStore.setClaimableDaily(false);
                    const daysComp = Math.floor(
                         difference.value / (1000 * 60 * 60 * 24)
                    );
                    const hoursComp = Math.floor(
                         (difference.value / (1000 * 60 * 60)) % 24
                    );

                    const minsComp = Math.floor(
                         (difference.value / 1000 / 60) % 60
                    );

                    const secsComp = Math.floor((difference.value / 1000) % 60);

                    days.value =
                         daysComp.toString().length == 1
                              ? "0" + daysComp
                              : daysComp;

                    hours.value =
                         hoursComp.toString().length == 1
                              ? "0" + hoursComp
                              : hoursComp;

                    minutes.value =
                         minsComp.toString().length == 1
                              ? "0" + minsComp
                              : minsComp;

                    seconds.value =
                         secsComp.toString().length == 1
                              ? "0" + secsComp
                              : secsComp;

                    difference.value = difference.value - 1000;
               } else {
                    props.onClaimStatus(difference.value <= 0);

                    if (difference.value == null) {
                         useBonusStore.setClaimableDaily(false);
                    } else {
                         useBonusStore.setClaimableDaily(true);
                    }

                    days.value = "00";
                    hours.value = "00";
                    minutes.value = "00";
                    seconds.value = "00";

                    difference.value = 0;

                    clearInterval(interval);
               }
          }

          const interval = () =>
               setInterval(() => {
                    countdownTimer();
               }, 1000);

          const openDailyBonus = () => {
               props.onClaimStatus(difference.value <= 0);
               eventBus.emit("open:dailybonus");
          };

          let x = 0;

          watch(data, (newVal) => {
               difference.value = null;
               if (newVal) {
                    difference.value =
                         +new Date(newVal["next_claim_start"]) -
                         +new Date(newVal["now"]);
                    if (x == 0) {
                         x++;
                         clearInterval(interval);
                         interval();
                    }
               }
          });

          return {
               dailyBonus,
               openDailyBonus,
               difference,
               days,
               hours,
               minutes,
               seconds,
               details,
               money,
               baseUrl
          };
     }
};
</script>
