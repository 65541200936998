<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenVerifyEmail"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
          >
               <div
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto text-center"
               >
                    <!-- Modal content -->
                    <div class="relative bg-shade-1 rounded-xl shadow-xl">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div class="pb-4 w-full text-center">
                                   <h3
                                        class="text-base xs:text-lg font-bold text-gray-900"
                                   >
                                        Welcome to
                                        <span
                                             class="text-background-3 font-bold"
                                             >MOOZI.COM</span
                                        >
                                   </h3>
                              </div>
                              <div class="pb-2 pt-10 w-full font-normal">
                                   <p class="">
                                        You need to verify your email address
                                        first
                                   </p>
                                   <!-- <p class="">Please click the button below to confirm your email address and activate your account.</p> -->
                                   <div
                                        class="pt-2 xs:pt-10 text-center w-full font-helveticaLight"
                                   >
                                        <div
                                             class="px-2 flex flex-col w-full gap-4"
                                        >
                                             <button
                                                  @click="resendVerification()"
                                                  class="w-full bg-secondary-3 text-primary-1 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                             >
                                                  Send link to email
                                             </button>
                                             <button
                                                  @click="closeModal()"
                                                  class="w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                             >
                                                  Later
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <loading-overlay :isLoading="loading" />
     <div
          v-if="isOpenVerifyEmail"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.3)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import { authStore } from "store/auth.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";

export default {
     components: {
          LoadingOverlay
     },
     setup() {
          const isOpenVerifyEmail = ref(false);
          const loading = ref(false);

          const useUtilitiesStore = utilitiesStore();
          const useAuthStore = authStore();

          const closeModal = () => {
               isOpenVerifyEmail.value = false;
               useUtilitiesStore.enableScroll();
          };

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const resendVerification = () => {
               loading.value = true;
               useAuthStore
                    .emailVerificationResend({ email: isUser.value.email })
                    .then(() => {
                         loading.value = false;
                         closeModal();
                    })
                    .catch(() => {
                         loading.value = false;
                         closeModal();
                    });
          };

          onMounted(async () => {
               eventBus.on("open:verify_email", () => {
                    useUtilitiesStore.disableScroll();
                    isOpenVerifyEmail.value = true;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:verify_email");
          });

          return {
               isOpenVerifyEmail,
               closeModal,
               baseUrl,
               resendVerification,
               loading
          };
     }
};
</script>
